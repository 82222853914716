var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "stageline-navigation" } }, [
    _c(
      "button",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.enableSideButtons,
            expression: "enableSideButtons",
          },
        ],
        staticClass: "button-navigate",
        attrs: {
          disabled: _vm.buttonLeftDisabled,
          "aria-label": "navigate left",
        },
        on: {
          click: function ($event) {
            return _vm.navigateStagesLeft()
          },
        },
      },
      [
        _c("fa-icon", {
          staticClass: "fa-lg",
          attrs: { icon: "chevron-left" },
        }),
      ],
      1
    ),
    _vm.stagesLoaded
      ? _c(
          "ol",
          { ref: "period-container", staticClass: "period-container" },
          _vm._l(_vm.stages, function (stage, index) {
            return _c(
              "li",
              {
                key: index,
                ref: "stages",
                refInFor: true,
                staticClass: "stage-container",
                class: `${_vm.iconStatus(index)}`,
              },
              [
                _c("div", { staticClass: "stage" }, [
                  _c(
                    "div",
                    {
                      staticClass: "icon-container",
                      class: _vm.requiresActionAlert(stage.name, index),
                    },
                    [
                      _c("hr", {
                        staticClass: "divider",
                        class: _vm.isFirstStage(index),
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "icon",
                          class: `${_vm.iconStatus(index)} ${
                            _vm.completed(index) ? "" : "clickable-icon"
                          }`,
                          on: {
                            click: function ($event) {
                              _vm.completed(index)
                                ? null
                                : _vm.navigateToStage(stage)
                            },
                          },
                        },
                        [
                          _c("stageline-navigation-icon", {
                            attrs: {
                              type: stage.icon_name,
                              "icon-status": _vm.iconStatus(index),
                              "requires-action":
                                _vm.checkForActionRequiredStages(stage.name),
                            },
                          }),
                        ],
                        1
                      ),
                      _c("hr", {
                        staticClass: "divider",
                        class: _vm.isLastStage(index),
                      }),
                    ]
                  ),
                  _c("p", { staticClass: "stage-title" }, [
                    _vm._v("\n          " + _vm._s(stage.title) + "\n        "),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _c(
      "button",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.enableSideButtons,
            expression: "enableSideButtons",
          },
        ],
        staticClass: "button-navigate",
        attrs: {
          disabled: _vm.buttonRightDisabled,
          "aria-label": "navigate right",
        },
        on: {
          click: function ($event) {
            return _vm.navigateStagesRight()
          },
        },
      },
      [
        _c("fa-icon", {
          staticClass: "fa-lg",
          attrs: { icon: "chevron-right" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }